body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}


/* Style the entire scrollbar */
::-webkit-scrollbar {
  width: 10px;  /* width for vertical scrollbar */
  height: 10px; /* height for horizontal scrollbar */
}

/* Style the track (background of the scrollbar) */
::-webkit-scrollbar-track {
  background: transparent;  /* light gray */
  border-radius: 20px;
}

/* Style the handle (thumb) */
::-webkit-scrollbar-thumb {
  background: #8887c7;  /* gray color for thumb */
  border-radius: 20px;
}

/* Style the handle when hovered */
::-webkit-scrollbar-thumb:hover {
  background: #240e95;  /* darker gray when hovered */
  cursor: grab;
}

/* Optional: Style the corners where the scrollbars meet */
::-webkit-scrollbar-corner {
  background: #d7d7d7;
}
